import { useEffect } from "react";
import SEOTags from "../components/seo-tags";

declare global {
  interface Window {
    Intercom: any;
  }
}

const Home = () => {
  useEffect(() => {
    window.location.href = `/login`;
  }, []);

  return (
    <>
      <SEOTags
        tags={{
          title: "Dashboard - Catlog",
          description: "Manage your catlog store, products, analytics and much more.",
          pageUrl: "/",
          image: "https://res.cloudinary.com/catlog/image/upload/v1674314911/seo-banners/Dashboard_Banner.png",
        }}
      />
      <div className="h-screen w-screen grid place-items-center text-primary-500">
        <div className="flex flex-col items-center space-y-3">
          <div className="spinner"></div>
          <p className="text-sm text-placeholder">Redirecting...</p>
        </div>
      </div>
    </>
  );
};

export async function getStaticProps(context) {
  return {
    props: {}, // will be passed to the page component as props
  };
}

export default Home;
